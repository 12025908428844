<template>
    <div class="rentQi">
        <div class="search" style="display: block">
             <a-tree-select
                style="width:400px;"
                :treeData="treeData"
                :value="value"
                @change="onChange"
                treeCheckable
                searchPlaceholder="请选择中心"
            />   
            <a-range-picker style="width:220px;margin:10px 16px;" format="YYYY-MM-DD" v-model="date" :placeholder="['起始时间','截止时间']" allowClear @change="changeTime"/>
            <a-input v-model="phone" class="search-style" placeholder="客户号码"/>
            <a-input v-model="userphone" class="search-style" placeholder="推荐人号码"/>
            <a-button class="search-style" @click="_clear">重置</a-button>
            <a-button type="primary" @click="_search">查询</a-button>
        </div>
        <a-table :columns="columns" :data-source="data" :pagination="false" bordered >
            <span slot="UserType" slot-scope="UserType">
                <span v-if="UserType==1">员工</span>
                <span v-if="UserType==2">非员工</span>
            </span>
        </a-table>
        <a-pagination v-if="total" style="margin-top: 35px;text-align: right;" @change="ChangePage" :pageSize="Limit" :total="total"  show-less-items />
    </div>
</template>

<script>

    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    import Vue from 'vue'
    Vue.use(Viewer)
    import { TreeSelect } from 'ant-design-vue';
    const SHOW_PARENT = TreeSelect.SHOW_PARENT;
    import moment from 'moment';
    import sSchool from '../../components/SelectSchool/SelectSchool';//选中心
    const columns = [
    {
        title: '客户姓名',
        dataIndex: 'StudentName',
        key: 'StudentName',
        slots: { title: 'StudentName' },
        scopedSlots: { customRender: 'StudentName' },
    },
    {
        title: '客户号码',
        dataIndex: 'Phone',
        key: 'Phone',
        slots: { title: 'Phone' },
        scopedSlots: { customRender: 'Phone' },
    },
    {
        title: '所属中心',
        dataIndex: 'SchoolName',
        key: 'SchoolName',
        slots: { title: 'SchoolName' },
        scopedSlots: { customRender: 'SchoolName' },
    },
    {
        title: '课程名称',
        dataIndex: 'Title',
        key: 'Title',
        slots: { title: 'Title' },
        scopedSlots: { customRender: 'Title' },
    },
    {
        title: '支付金额',
        dataIndex: 'TotalFee',
        key: 'TotalFee',
        slots: { title: 'TotalFee' },
        scopedSlots: { customRender: 'TotalFee' },
    },
    {
        title: '交易时间',
        dataIndex: 'TimeEnd',
        key: 'TimeEnd',
        slots: { title: 'TimeEnd' },
        scopedSlots: { customRender: 'TimeEnd' },
    },
    {
        title: '推荐人',
        dataIndex: 'UserName',
        key: 'UserName',
        slots: { title: 'UserName' },
        scopedSlots: { customRender: 'UserName' },
    },
    {
        title: '推荐人号码',
        dataIndex: 'UserPhone',
        key: 'UserPhone',
        slots: { title: 'UserPhone' },
        scopedSlots: { customRender: 'UserPhone' },
    },
    {
        title: '推荐人类型',
        dataIndex: 'UserType',
        key: 'UserType',
        slots: { title: 'UserType' },
        scopedSlots: { customRender: 'UserType' },
    },
    ];

     var type_Arr=[];//监听分类帅选条件
    export default {
        name: "salescourse",
        data(){
            return{
                Page:0,
                Limit:10,
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                spinning:false,              
                visible:false,
                data:[],
                columns,
                total:0,
                phone:'',
                userphone:'',
 
                SHOW_PARENT,
                spinning: false,
                schoolList:'',
                startDate:'',
                endDate:'',
                date: [this.startDate,this.endDate],
                treeData:[],
                value:[]
            }
        },
        created(){
            this._loadData();
            this.$axios.get(9980,{},res=>{  console.log(res.data.data); this.treeData = res.data.data; })
        },
        components:{sSchool,},
        methods:{
            moment,
            _loadData(){
                this.spinning = true;
                let self = this;
                this.$axios.get(7000001,{
                    phone:self.phone,
                    userphone:self.userphone,
                    page:self.Page,
                    limit:self.Limit,
                    SchoolId:self.schoolList,
                    StartDate:self.startDate,
                    EndDate:self.endDate
                },res=>{
                    self.spinning = false;
                    self.data =res.data.data.list;
                    self.total = res.data.data.count;

                })
            },
            _clear(){
                this.phone = '';
                this.userphone = '';
                this.startDate = '';
                this.endDate = '';
                this.date = [];
                this.value = [];
                console.log(this.date)
                this.schoolList = '';
                this._loadData();
            },
            
            _search(){
                this.Page=0;
                this._loadData();
            },
            ChangePage(value){
                this.Page = value;
                this._loadData();
            },

               //选中心
          
             //搜索时间
            changeTime(value) {
                // console.log(value)
                this.startDate = value[0]._d;
                this.endDate = value[1]._d;
                let date1 = new Date(this.startDate);
                this.startDate = date1.getFullYear()+'-'+(date1.getMonth()+1<10?'0'+(date1.getMonth()+1):date1.getMonth()+1)+'-'+(date1.getDate()<10?'0'+date1.getDate():date1.getDate());
                let date2 = new Date(this.endDate);
                this.endDate = date2.getFullYear()+'-'+(date2.getMonth()+1<10?'0'+(date2.getMonth()+1):date2.getMonth()+1)+'-'+(date2.getDate()<10?'0'+date2.getDate():date2.getDate());
                // console.log(this.startDate,this.endDate)  
            },
             //选中心
              onChange(value) {
                // console.log('onChange ', value,arguments);
                this.value = value;
                this.schoolList = value.join(',');
            },  
            
        }
    }
</script>

<style scoped lang="less">
    .rentQi{
        background: #ffffff;
        margin-top: 15px;
        min-height: 800px;
        padding: 15px;
    }
    .search{
        margin-bottom: 20px;
        .search-style{
            display: inline-block;
            width: 120px;
            margin-right: 15px;
        }
    }
   
</style>